import { MP } from "./zone";

export const ApplicationHeader = (props: { merchantPartner: MP }) => {
  return (
    <>
      <div className="header-wrapper-bottom">
        <div className="container g-0 p-0">
          <nav className="navbar navbar-expand-lg navbar-light navbar-onboarding py-1 py-lg-0">
            <div className="container-fluid">
              <span className="navbar-brand py-2 ps-5 ps-lg-0 py-lg-3 w-100 mx-3 mx-lg-0">
                <div className="d-flex justify-content-between">
                  <div>
                    <img
                      src="PB_Logo.png"
                      className="header-logo"
                      alt="PB-Personal-Finance"
                    />
                  </div>
                  {props.merchantPartner.valueOf() === MP.Ardes && (
                    <div>
                      {/* <!-- Remove this div if there is no merchant logo--> */}
                      <img
                        src="00850-0000.svg"
                        className="header-logo"
                        alt=""
                      />
                    </div>
                  )}

                  {props.merchantPartner.valueOf() === MP.Technomarket && (
                    <div>
                      {/* <!-- Remove this div if there is no merchant logo--> */}
                      <img
                        src="01115-0000.svg"
                        className="header-logo"
                        alt="BNP-PARIBAS"
                      />
                    </div>
                  )}

                  {props.merchantPartner.valueOf() === MP.Technopolis && (
                    <div>
                      {/* <!-- Remove this div if there is no merchant logo--> */}
                      <img
                        src="01300-0000.svg"
                        className="header-logo"
                        alt="BNP-PARIBAS"
                      />
                    </div>
                  )}

                  {props.merchantPartner.valueOf() === MP.JARComputers && (
                    <div>
                      {/* <!-- Remove this div if there is no merchant logo--> */}
                      <img
                        src="01400-0000.svg"
                        className="header-logo"
                        alt="BNP-PARIBAS"
                      />
                    </div>
                  )}

                  {props.merchantPartner.valueOf() === MP.Plasico && (
                    <div>
                      {/* <!-- Remove this div if there is no merchant logo--> */}
                      <img
                        src="01806-0000.svg"
                        className="header-logo"
                        alt="BNP-PARIBAS"
                      />
                    </div>
                  )}

                  {props.merchantPartner.valueOf() === MP.FourChairs && (
                    <div>
                      {/* <!-- Remove this div if there is no merchant logo--> */}
                      <img
                        src="01806-0007.svg"
                        className="header-logo"
                        alt="BNP-PARIBAS"
                      />
                    </div>
                  )}

                  {props.merchantPartner.valueOf() === MP.Zora && (
                    <div>
                      {/* <!-- Remove this div if there is no merchant logo--> */}
                      <img
                        src="04001-0001.svg"
                        className="header-logo"
                        alt="BNP-PARIBAS"
                      />
                    </div>
                  )}

                  {props.merchantPartner.valueOf() === MP.MebelMag && (
                    <div>
                      {/* <!-- Remove this div if there is no merchant logo--> */}
                      <img
                        src="06410-0003.svg"
                        className="header-logo"
                        alt="BNP-PARIBAS"
                      />
                    </div>
                  )}

                  {props.merchantPartner.valueOf() === MP.Videnov && (
                    <div>
                      {/* <!-- Remove this div if there is no merchant logo--> */}
                      <img
                        src="07220-0000.svg"
                        className="header-logo"
                        alt="BNP-PARIBAS"
                      />
                    </div>
                  )}

                  {props.merchantPartner.valueOf() === MP.Emag && (
                    <div>
                      {/* <!-- Remove this div if there is no merchant logo--> */}
                      <img
                        src="Logo emag.svg"
                        className="header-logo"
                        alt="BNP-PARIBAS"
                      />
                    </div>
                  )}
                </div>
              </span>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};
